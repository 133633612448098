.logo {
  object-fit: contain;
}

.backdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
}

.helpIcon {
  margin-left: 4px;
  vertical-align: middle;
  font-size: large;
}