.actionButton {
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 8px;
}
.menuActionButton {
  background-color: rgba(255, 255, 255, 0.8);
}

.boxActionButton {
  display: inline-block;
  border-radius: 50%;
}

.actionButton:hover {
  background-color: white;
}

.actionButton:disabled {
  background-color: rgb(255 255 255 / 25%);
}

.resizeControl {
  position: absolute;
  top: 48px;
  left: -8px;
  z-index: 20;
  margin-top: -40px;
}

.buttonBar {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  display: flex;
  flex-flow: row-reverse;
}

.controlsContainer {
  height: 100%;
}

.galleryBtn {
  color: rgba(0, 0, 0, 0.54);
  background-color: rgb(255 255 255 / 25%) !important;
  border: 1px solid rgba(0, 0, 0, 0.54);
  font-size: 24px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  cursor: pointer;
}

.galleryBtn:hover {
  background-color: rgb(255 255 255 / 75%) !important;
}

.galleryHorizontalBtn {
  width: 36px;
  min-width: 36px;
  height: 64px;
}

.galleryVerticalBtn {
  width: 36px;
  height: 36px;
}

.galleryBtnBack {
  margin-left: 8px;
}

.galleryBtnNext {
  margin-right: 8px;
}

.galleryBtnUp {
  margin-top: 8px;
  width: fit-content;
}

.galleryBtnDown {
  margin-bottom: -24px;
  width: fit-content;
}

.ribbonAndArrowsContainer {
  width: 100%;
}

.ribbonAndArrowsContainerPartialScreen {
  height: 100%;
}

.ribbonAndArrowsContainerFullscreen {
  height: 100vh;
}

.arrowsContainer {
  width: 100%;
}

.tabsContainer {
  width: 100%;
}
