.leftContainer {
  display: flex;
  align-items: left;
  color: #777;
  flex-direction: column;
  padding-left: 4px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  width: 100%;
  color: black;
}

.deleteButton {
  position: relative;
  padding: 0;
  right: -4px;
}

.deleteIcon {
  color: rgba(0, 0, 0, 0.26);
  font-size: 22px;
}

.filterChipContainer {
  width: 100%;
  margin-bottom: 8px;
}

.filterChip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 4px 8px;
  border: 1px solid #ccc;
  width: 100%;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 3px 4px -2px rgb(0 0 0 / 25%);
  border-radius: 4px;
}
