.container {
  height: calc(100% - 20px) !important;
  width: 350px;

  margin: 10px;
}

.containerForMediaViewer {
  height: calc(100% - 54px) !important;

  margin: 0 10px 10px;
}

.paper {
  background-image: none; /* When in dark mode, it was adding linear gradient that change the color */
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  position: relative;

  height: 100%;
  border-radius: 6px;
}

.paperForMediaViewer {
  background-image: none; /* When in dark mode, it was adding linear gradient that change the color */
  background-color: #fff;
  overflow: hidden;
  position: relative;

  height: 40%;
  border-radius: 0 0 6px 6px;
}

:global(#media-viewer-tab-content) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

:global(.krpano-viewer-target) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.inProgressOverlay {
  color: #fff;
  height: 100%;
  position: absolute;
}

