.wrapper {
  width: 100%;
  padding: 0px 8px 8px 8px;
  margin: 0;
}

.fieldInput {
  min-width: 300px;
  margin-bottom: 16px;
}

.fieldInputAutocomplete {
  max-width: 300px;
  width: auto;
  display: inline-block;
}
