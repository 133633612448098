.measurementToolData {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.mapControls-responsive {
  margin-bottom: 10px;
}

.mapControls-infoCollapsed {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
