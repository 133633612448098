.imgOverflow {
  overflow: hidden;
}
.pngGrid {
  cursor: crosshair;
  background: conic-gradient(
    #e6e6e6 90deg,
    #b4b4e4 90deg 180deg,
    #e6e6e6 180deg 270deg,
    #b4b4e4 270deg
  );
  background-size: 10px 10px;
  background-position: top left;
  background-repeat: repeat;
  overflow: hidden;
}
.boxBg{
    background: #808080;
}
.toggleButtonGroupStyle{
    right: 15px;
    position: absolute;
    margin-top: 10px;
}
.toggleButton{
    background: #656565;
    border: 1px black;
}
.iconColor{
    color: white;
}
.navButton{
    margin-top: 15px; 
    margin-bottom: 15px; 
    margin-left: -15px;
    margin-right: 15px;
}


