.paper {
  border-radius: 4px;
  background-color: #e9e9e9;
  border: 1px solid #ccc;
}

.leftContainer {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
}

.headerLabel {
  margin: 0px 0px;
}

.settingsIcon {
  width: 20px;
  height: 20px;
}

.content {
  padding: 0px 4px 4px;
}

.deleteIcon {
  color: rgba(0, 0, 0, 0.26);
  font-size: 22px;
}
