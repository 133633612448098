.container {
  /* position relative is needed to override the parent container's position: static, which prevents z-index from having an effect */
  position: relative;
  z-index: 10;
}

.tabContainer {
  display: inline-block;
  /* position relative is needed to override the parent container's position: static, which prevents left from having an effect */
  position: relative;
  left: 14px;
}

.tabContainerOffset {
  margin-bottom: 1px;
}

.tab {
  border-radius: 4px 4px 0 0;
  padding: 4px;
  margin-right: 4px;
}

.resizeControlContainer {
  width: fit-content;
  height: fit-content;
  display: inline-block;
  position: absolute;
  right: 8px;
  margin-top: 25px;
  z-index: 15;
}

.contentContainer {
  overflow: hidden;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
