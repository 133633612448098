.listHeaderContainer {
  min-height: 56px;
  padding: 8px;
}

.queryField {
  width: 500px;
}

.selectFilter {
  width: 150px;
}

.list {
  padding-top: 0;
  padding-bottom: 0;
}

.actions {
  margin-right: 8px;
  border: 1px;
}
