.wrapper {
  width: 100%;
  padding: 0px 16px 8px 8px;
  margin: 0;
}

.maxUploadLengthTextContainer {
  margin-bottom: 16px;
}

.fieldInput {
  min-width: 300px;
  margin-bottom: 16px;
}
