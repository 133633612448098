.menuWrapper {
  position: absolute;
  left: 8px;
  top: 8px;
}
.menuIconExpanded,
.menuIconExpanded:hover {
  width: fit-content;
  margin-left: 0;
}

.mapMenuPopover {
  background: none;
  box-shadow: none;
  margin-top: 8px;
  overflow: hidden;
}

.menuItem {
  margin-bottom: 8px;
}

.menuItems {
  display: block;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 100;
  overflow: auto;
}

.menuItemsWithoutMenuWrapper {
  display: block;
  overflow: auto;
  z-index: 100;
  padding-top: 4px;
}

.hideMenuItems {
  display: none;
}

.menuItems-inner {
  transform: scaleX(-1);
}

.scrollbar {
  transform: scaleX(-1);
}