.krpanoContainer {
  position: relative;
}

.krpanoContainer-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.feedbackContainer {
  width: 100%;
  height: 100%;
  background-color: black;
}

.loader {
  color: lightgrey;
}

.noXMLMessage {
  color: white;
  width: 200px;
  border: 1px solid white;
  padding: 2rem;
}

.krpanoViewerModalBackdrop {
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1299; /* this is 1 less than the z-index used for the krpano-portal */
  background-color: rgba(0, 0, 0, 0.5);
}
