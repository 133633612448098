.container {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
}

.commentsHeader {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
}

.commentsCount {
  font-size: 15px;
  margin-right: 36px;
}

.linkBtn {
  color: #1976d2;
}

.viewAllCommentsBtn {
  margin-right: 8px;
}

.floatingFooter {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  /* padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 8px; */
  padding-bottom: 8px;
}

.floatingFooterNoHeader {
  padding-top: 8px;
}

.inputHeader {
  padding-left: 8px;
  padding-right: 8px;
}

.inputHeaderText {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.editingIcon {
  font-size: 15px;
}
