.clusterBtn {
  min-width: 0px;
  width: 23px;
  height: 23px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%) !important;
}

.clusterIcon {
  font-size: 15px;
}

.declusterIcon {
  font-size: 15px;
  position: absolute;
}

.declusterIconFront {
  left: 0;
  margin-left: auto;
  right: 0;
  margin-right: auto;
  top: 6px;
  z-index: 1;
  color: #757575;
}

.declusterIconBack1 {
  top: 3px;
  left: 9px;
  color: #bdbdbd;
}

.declusterIconBack2 {
  top: 3px;
  right: 9px;
  color: #bdbdbd;
}
