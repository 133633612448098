.searchContainer {
  border-radius: 0.75rem;
  padding: 1rem;
  position: absolute;
  right: 10px;
  width: 320px;
  display: flex;
  flex-direction: column;
}

.searchTotalResults {
  font-size: 0.825rem;
  padding: 0.5rem 0 1rem;
  margin: 0;
}

.searchResult {
  border-bottom: 1px solid #ccc;
}

.searchIcon {
  position: relative;
  right: -1rem;
}

.scrollbarResultsContainer {
  margin-top: 12px;
  flex: 1;
  position: relative;
}
