.page {
  flex: 1;
  display: flex;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
}
