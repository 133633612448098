.filterField {
  width: 160px;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  gap: 16px;
}

.publishButton {
  white-space: nowrap;
}