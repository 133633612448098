.toggleButtonGroup {
  border-radius: 0px;
}

.toggleButton {
  justify-content: flex-start;
  border-radius: 0px;
}

.toggleContentContainer {
  text-align: left;
}
