.topBar {
  margin-top: 0px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  min-height: 72px;
}

.sortByField {
  width: 175px;
}

.imgSizeField {
  width: 150px;
}

.mediaContainer {
  flex: 1;
  width: 100%;
}

.mediaGroups {
  min-height: 110px;
}

.imageSizeSlider {
  width: 100px;
}
.imageSizeSliderContainer {
  padding-bottom: 16px;
}
.mediaGroupImages {
  margin-right: 32px;
}

.loader {
  margin-right: 8px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.listContainer {
  display: flex;
  flex: 1;
}

.groupContainer {
  flex: 1;
}

.groupContentContainer {
  background-color: white;
}

.groupContentFiller {
  height: 16px;
}

.groupCheckbox {
  margin-left: -12px;
}

.selectedImageContainer {
  border-radius: 4px;
  overflow: hidden;
  border-style: solid;
  border-width: 3px;
}