.mapOverlayExpanded {
    width: 100%;
    height: 100%;
}

.mapOverlayCollapsed {
    width: 100%;
    height: auto;
}

.paperExpanded {
    overflow: auto;
    height: 100%;
}

.paperCollapsed {
    overflow: hidden;
    height: auto;
}

.smallScreenWidth {
    width: auto;
    max-width: 290px;
}

.largeScreenWidth {
    width: 350px;
}

.contentCollapsed {
    display: none;
}

.scrollbar {
    width: 100%;
    height: 100%;
}

.rotatingIcon {
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.expandIcon {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
}

.collapseIcon {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}