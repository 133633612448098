.inProgressOverlay {
  color: #fff;
  height: 100%;
  position: absolute;
}

.fieldLabelCell {
  border-bottom: none;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 1rem;
  text-align: left;
  width: 15%;
}
