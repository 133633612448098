.container {
  padding: 10px 3.75px 10px 10px;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1000;
}
.containerSmall {
  margin: 10px 3.75px 10px 10px;
  width: 50%;
  height: calc(100% - 20px);
  flex-grow: 1;
  position: relative;
  z-index: 1000;
}

.paper {
  overflow: hidden;
  height: 100%;
}

.header {
  margin: 0px 16px 8px;
  padding-top: 8px;
}

.filterField {
  width: 160px;
}

.hidden {
  display: none;
}

.mediaScrollContainer {
  /* NOTE: 56px is the height of the top bar including margin and padding, without accounting for this here, the scroll
    area overflows down off the component. If the topbar height or vertical margins/padding is changed, this also needs to be updated.
  */
  height: calc(100% - 56px);
  display: flex;
}
