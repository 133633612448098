.accordion {
  box-shadow: none;
  width: 160px;
  border-top: 1px solid #ccc;
}

.accordionItem {
  display: flex;
  align-items: center;
}

.accordionItemCheckbox {
  padding: 0;
  margin-right: 4px;
}

.accordionItemDisabled {
  cursor: not-allowed !important;
}

.accordionDetails {
  max-width: 250px;
}
