.previewCard {
  width: 275px;
  background-image: none; /* When in dark mode, it was adding linear gradient that change the color */
}

.closeButtonContainer {
  position: absolute;
  right: 4px;
  top: 4px;
}

.closeButton {
  padding: 6px;
}

.closeIcon {
  width: 15px;
  height: 15px;
}

.arrowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20px;
}

.arrowDown {
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
