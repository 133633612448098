.ribbonScrollBar {
  display: flex;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-right: 8px;
  padding-left: 8px;
}

.ribbonDotImage {
  height: 76px;
  width: 76px;
  object-fit: cover;
  cursor: pointer;
  flex-shrink: 0;
}

.ribbonDotImageActive {
  height: 76px;
  width: 76px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid #21b5f5;
  flex-shrink: 0;
}

.ribbonDotImageContainer {
  margin-right: 4px;
}

.imageList {
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 0px;
}
