.container {
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%) !important;
}

.zoomBtn {
  min-width: 0;
  border-radius: 0;
  padding: 4px;
}

.zoomIcon {
  font-size: 15px;
}
