.container {
  height: calc(100% - 20px) !important;
  width: 350px;
  margin: 10px;
}

.paper {
  background-image: none; /* When in dark mode, it was adding linear gradient that change the color */
  background-color: #eeeeee;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}

.zeroVerticalPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.horizontalTextOverflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fieldLabelCell {
  border-bottom: none;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 1rem;
  text-align: left;
  width: 15%;
}

.tableCellVerticalAlignTop {
  vertical-align: top;
}
