.container {
  overflow: hidden;
  margin: 8px;
}

.paper {
  border-radius: 8px;
}

.accordionSummary {
  padding: 0px 8px 0px 8px;
  min-height: 30px;
}

.accordionDetails {
  padding: 0px;
  margin: 0px 12px 8px 12px;
  width: 179px;
}

.title {
  margin-right: 4px;
}

.dataText {
  width: 179px;
  display: inline-block; /* This is needed to make the text truncate */
}

.resetBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 4px;
}

.resetBtn {
  border-radius: 8px;
  align-self: flex-end;
}
