.container {
  position: relative;
  display: flex;
}

.placeholderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loadingSkeleton {
  width: 100%;
  height: 100%;
}

.errorPlaceholder {
  height: calc(100% / 2.5);
  width: calc(100% / 2.5);
}

/*
  NOTE: setting dimensions to 0 instead of setting display to none is to support use cases where lazy loading is used
  The image will not lazy load if display is set to none, but it does load with 0 dimension
*/
.errorImg {
  height: 0px !important;
  width: 0px !important;
  /* Need to set border to none because any border styling will add dimension */
  border: none !important;
  /* Need to set margin to 0 because any styling will add dimension */
  margin: 0px !important;
  /* Need to set padding to 0 because any styling will add dimension */
  padding: 0px !important;
  /* Need to set to display block otherwise a display flex style will add dimension */
  display: block;
}

.cornerIcon {
  position: absolute;
}

.cursorPointer {
  cursor: pointer;
}

.cornerIconAutoScaled {
  max-width: 24px;
  max-height: 24px;
  height: calc(100% / 5);
  width: calc(100% / 5);
  min-width: 14px;
  min-height: 14px;
}

.cornerIconNoImg {
  color: rgba(0, 0, 0, 0.26);
}

.cornerIconWithImg {
  color: rgba(255, 255, 255, 0.7);
  filter: drop-shadow(0px 0px 2px rgb(0, 0, 0, 0.7));
  -webkit-filter: drop-shadow(0px 0px 2px rgb(0, 0, 0, 0.7));
}

.bottomLeftIcon {
  bottom: 3px;
  left: 3px;
}

.bottomRightIcon {
  bottom: 3px;
  right: 3px;
}

.topLeftIcon {
  top: 3px;
  left: 3px;
}

.topRightIcon {
  top: 3px;
  right: 3px;
}
