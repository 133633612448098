.fullHeight {
  height: 100%;
}

.headerFooter {
  position: sticky;
  z-index: 2;
}

.header {
  top: 0px;
  padding: 16px;
}

.footer {
  bottom: 0px;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.stepsContent {
  padding-left: 16px;
  padding-right: 16px;
}

.hiddenBtn {
  visibility: hidden;
}