.container {
  display: flex;
  width: fit-content;
  height: fit-content;
}

.verticalContainer {
  flex-direction: column;
}

.horizontalContainer {
  flex-direction: row;
}

.arrowBtn {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.54);
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%);
  min-width: 0;
  padding: 0;
  margin: 0;
}

.arrowBtn:hover {
  background-color: #e9e9e9;
}

.leftBtn {
  width: 18.4px;
  height: 23px;
  border-radius: 4px 4px 0 0;
}

.rightBtn {
  width: 18.4px;
  height: 23px;
  border-radius: 0 0 4px 4px;
}

.upBtn {
  height: 18.4px;
  width: 23px;
  border-radius: 4px 0 0 4px;
}

.downBtn {
  height: 18.4px;
  width: 23px;
  border-radius: 0 4px 4px 0;
}

.arrows {
  width: 14px;
}
