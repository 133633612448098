.navItem {
  margin-left: 0;
  padding: 0;
  height: 36px;
}
.navItemExpanded {
  margin-left: 0.25rem;
  padding: 0;
  height: 36px;
}
