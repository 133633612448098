.searchToggleButton {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 0.5rem 0 0 0.5rem;
    position: absolute;
    left: -2.25rem;
    top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}