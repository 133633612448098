.outlinedInput {
  padding-right: 9px;
  cursor: pointer;
}

.outlinedInput input {
  display: none;
}

.colorDisplay {
  height: 20px;
  width: 100%;
  border-radius: 4px;
  margin-right: 2px;
}

.arrowBtn {
  padding: 2px;
  margin-top: 6.5px;
  margin-bottom: 6.5px;
}
