.fileFieldContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.valueContainer {
  flex: 1;
  padding: 0 12px;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.closeIcon {
  margin-left: 2px;
}

.inputLabel {
  position: relative;
  transform: none;
  margin-bottom: 8px;
}

.helperTextError {
  margin: 0;
  margin-top: 4px !important;
  position: relative;
  transform: none;
  display: block;
}
