.menu {
  margin-left: 11px;
  margin-top: -9.5px;
}

.paper {
  overflow: visible;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), -17px 17px 20px 2px rgb(0 0 0 / 17%);
}

/* Make an arrow in the bottom left of the menu pointing to the map coordinate */
.paper::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -25px;
  left: -11px;
  width: 0;
  height: 0;
  border-top: 24px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  transform: translateY(-50%) rotate(45deg);
  z-index: 0;
}
