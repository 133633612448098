.queuedUploader .uppy-Container {
  height: 100%;
}

.queuedUploader .uppy-Root {
  height: 100%;
}

.queuedUploader .uppy-Dashboard {
  height: 100%;
}