.imageInfoContainer {
  min-width: fit-content;
  width: 50%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.imageTagsContainer {
  width: min-content;
  min-width: 253px;
}

.imageTagsContainer-singleColumn {
  min-width: fit-content;
  width: 100%;
}

.commentsHeader {
  margin-top: 8px;
}

/* A div with "ps__rail-y" class */
.no-scrollbar > div:last-of-type {
  display: none !important;
}
