.listItem {
  position: relative;
}

.avatar {
  width: 35px;
  height: 35px;
  font-size: 15px;
}

.commentAndAvatarContainer {
  display: flex;
  align-items: flex-start;
  padding-right: 8px;
}

.deletedCommentAndAvatarContainer {
  display: flex;
  align-items: center;
  padding-right: 8px;
}

.listItemAvatar {
  min-width: 45px;
  margin-top: 4px;
}

.actionsContainer {
  margin-left: 45px;
}

.repliesList {
  margin-left: 32px;
}

.replyComment {
  padding-right: 0px;
}

.timestampContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
