#raster-overlay-uploader .uppy-Container {
  height: 100%;
}

#raster-overlay-uploader .uppy-Root {
  height: 100%;
}

#raster-overlay-uploader .uppy-Dashboard {
  height: 100%;
}