.container {
  /* prevent horizontal scrollbar from appearing at some window sizes */
  overflow: hidden;
}

.imgAttrLabelCell {
  padding: 6px 10px;
  width: 127.525px;
}

.imgAttrValueCell {
  width: 130.34px;
  max-width: 130.34px;
  padding: 6px 10px 6px 0px;
}
