.infoContainer {
  width: 150px;
}

.divider {
  margin-left: 16px;
  margin-right: 16px;
}

.userChip {
  max-width: 150px;
}
