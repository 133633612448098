.container {
  background-color: white;
  padding: 16px;
  margin-top: 8px;
  text-align: center;
}

.title {
  font-size: 32;
  text-transform: uppercase;
  display: block;
}

.image {
  max-width: 500px;
  min-width: 240px;
}

.buttonContainer {
  margin: 1rem auto;
  width: 440px;
}

.button {
  border: 1px solid #ccc;
}
