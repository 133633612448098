.dialog {
  /* NOTE: 64px is derived from the top and bottom margin applied to dialogs when filling the screen */
  height: calc(100dvh - 64px);
}

.fullHeight {
  height: 100%;
}

.form {
  /* This prevents default display: block styling from messing with style */
  display: contents;
}

.placementPanel {
  position: absolute;
  bottom: 8px;
  left: 8px;
  margin-right: 8px;
}

.placementPanelTextInfo {
  margin-right: 12px;
}

.placementPanelInfoBox {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 371px;
  min-height: 60px;
  border: none!important;
}

.paper {
  border-radius: 4px;
}

.statusBarContainer {
  width: 300px;
}

.closeSnackbarIcon {
  color: white;
}

.uppyStatusBarContainer {
  width: 300px;
}