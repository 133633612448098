.menuExpanded {
    position: absolute;
    right: -16px;
    top: 72px;
    width: 30px;
    height: 30px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.menuExpanded:hover {
    background-color: #e9e9e9;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

.menu {
    position: absolute;
    right: 4px;
    top: 16px;
    width: 30px;
    height: 30px;
    z-index: 1000;
    background-color: #555;
    box-shadow: none;
}
.menu:hover {
    background-color: #333;
}

.menuIconExpanded {
    color: #555;
}

.menuIcon {
    color: white;
}