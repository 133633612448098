.viewerContainer {
  display: flex;
  flex-direction: row;
}

.tooltip {
  background-color: rgba(97, 97, 97, 0.92) !important;
  border-radius: 4px;
  color: rgb(255, 255, 255) !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  padding: 4px 8px !important;
  font-size: 0.6875rem;
  max-width: 300px;
  margin: 2px;
  overflow-wrap: break-word;
  font-weight: 500;
  margin-left: 15px;
  margin-top: -5px;
}
