.container {
  height: 100%;
}

.mapControls {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.thumbnailContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}