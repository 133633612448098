.mapMenuItemContainer {
  width: auto;
}

.mapMenuItemBtnContainer {
  display: flex;
  justify-content: flex-start;
}

.mapMenuItemBtn {
  text-align: left;
  justify-content: flex-start;
}

.menuBtn svg {
  margin-right: 4px;
}

.menuBtnInactive {
  width: 90px;
  border-radius: 8px 8px 8px 8px;
  color: #333;
  padding: 6px 12px;
}

.menuBtnInactive:hover {
  width: 125px;
  color: inherit;
}

.menuBtnActive {
  color: #e17008 !important;
  border-radius: 8px 8px 0px 0px;
  width: 107.5px;
}

.menuBtnIconContainer {
  margin-right: 4px;
  display: flex;
}

.mapMenuItemContent {
  min-width: 160px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 0 8px 8px 8px;
  /* box-shadow matches the elevation applied to the MUI buttons used for menuBtn */
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 6px -2px rgb(0 0 0 / 25%);
}
