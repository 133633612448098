.radar {
  width: 60px;
  height: 60px;
}

.radar__main_dial {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.radar__window {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  overflow: hidden;
}

.radar__background {
  top: 0;
  left: 0;
  height: 100%;
  width: 200%;
  margin-left: -100%;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjEuMCIgeTE9IjAuNSIgeDI9IjAuMCIgeTI9IjAuNSI+PHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4wIi8+PHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNmZmE1MDAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
  background-size: 100%;
  background-image: -moz-linear-gradient(180deg, transparent 50%, rgba(255, 166, 0, 0.5) 50%);
  background-image: -webkit-linear-gradient(180deg, transparent 50%, rgba(255, 166, 0, 0.5) 50%);
  background-image: linear-gradient(-90deg, transparent 50%, rgba(255, 166, 0, 0.5) 50%);
}

.radar__dot {
  position: absolute;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  margin-top: -3px;
  margin-left: -3px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgba(255, 166, 0, 0.5);
  background-color: rgba(255, 255, 0, 0.5);
}
