.wrapper {
  width: 100%;
  padding: 0px 8px 8px 8px;
  margin: 0;
}

.maxUploadLengthTextContainer {
  margin-bottom: 16px;
}

.fileInputContainer {
  margin-top: 16px;
}
