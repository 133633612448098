.container {
  margin: 10px;
  position: relative;
}

.containerExpanded {
  height: calc(100% - 20px);
}

.floatingActionsContainer {
  position: absolute;
  right: 8px;
  top: 8px;
}

.smallScreenWidth {
  width: 300px;
}

.largeScreenWidth {
  width: 350px;
}

.autoWidth {
  width: auto;
}

.scrollBarContainer {
  position: absolute;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.card {
  background-image: none; /* When in dark mode, it was adding linear gradient that change the color */
  overflow: hidden;
  height: 100%;
}

.card-expanded {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: auto;
}

.collapsedContainer {
  padding: 8px;
}

.mediaViewerIconSpaced {
  margin-right: auto;
  margin-left: 16px;
}

.collapsedTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.actions {
  display: flex;
  justify-content: flex-end;
  border-color: black;
  border-width: 5px;
}

.floatingAction {
  margin-left: 8px;
}

.rotatingIcon {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.expandIcon {
  transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
}

.expandSmallScreenIcon {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.mediaViewerIcon {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.collapseIcon {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}

.collapseSmallScreenIcon {
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}

.totalChip {
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 30%;
}

.hidden {
  display: none;
}

.mediaHeader {
  margin-top: 8px;
}

.viewAllImageryBtn {
  border-radius: 8px;
  margin-bottom: 8px;
}

.cardContentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 0;
}

.locationInfoContainer {
  padding: 16px;
}

.activePanelContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0 16px;
}

.buttonsPanel {
  flex-direction: column-reverse;
}

.buttonsPanelExpanded {
  flex-direction: row;
  width: 100%;
  position: absolute;
}

.marginLeftAuto {
  margin-left: auto;
}