.navTree {
  margin-bottom: 60px;
}
.navTreeExpanded {
  margin-bottom: 0;
}

.drawerPosition {
  position: relative;
}
.drawerPositionMobile {
  position: absolute;
}

.drawer {
  width: 40px;
  top: 64px;
  height: calc(100% - 64px);
  overflow: visible;
  position: fixed;
}
.drawerExpanded {
  width: 256px;
  top: 64px;
  height: calc(100% - 64px);
  overflow: visible;
  position: fixed;
}
