.container {
  /* this is needed for the delete icon to be positioned correctly for non repeatable image answer */
  width: fit-content;
}

.deleteIcon {
  color: rgb(245, 7, 7, 0.6);
}

.deleteIcon:hover {
  color: rgb(245, 7, 7, 0.8);
}