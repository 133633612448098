.conditionalQuestion {
  width: 98%;
  margin-left: auto;
}

.questionAfterConditional {
 margin-bottom: 8px;
}

.questionBeforeConditional {
  margin-top: 8px;
}
 
/*This removes the darker grey line between accoridan elements where there is a gap*/
.questionBeforeConditional::before {
  display: none;
}

.questionBeforeNotConditional::before {
  display: none;
}