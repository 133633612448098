.root {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-left: 40px;
}
.wrapperExpanded {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-left: 256px;
  position: relative;
}
.contentContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}
.content {
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}
