.mapControls {
  position: absolute;
  bottom: 10px;
}

.mapControls-searchExpanded {
  right: 340px;
}

.mapControls-searchCollapsed {
  right: 10px;
}
