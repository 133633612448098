.listContainer {
  padding: 0;
  margin: 0;
  margin-top: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.listItemContainer {
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
}

.noUsersContainer {
  padding: 16px;
}
