.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.fullscreen {
  height: 100vh !important;
}

.logoContainer {
  margin-bottom: 48px;
}

.logo {
  width: 200px;
  max-width: 100%;
}
