.accordion-wide {
  padding: 0px;
}

.accordion-noBorder {
  box-shadow: none;
}

.accordion-noBackground {
  background-image: none; /* When in dark mode, it was adding linear gradient that change the color */
  background-color: transparent !important;
}

.accordion-dense .MuiAccordionSummary-content {
  margin: 0px 0px;
}

.centerChildren {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editFormContainer {
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.buttonIcon {
  margin-right: 8px;
}

.krpano-portal {
  position: fixed;
  /* MUI modal level of z-index is 1300, allow modals to appear over it */
  z-index: 1299;
  visibility: hidden;
}

.krpano-viewer-target {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.54);
  overflow: hidden;
}

.krpano-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  pointer-events: none;
}

.krpanoControl {
  pointer-events: all;
}
