.flagFilterBtn {
  min-width: 0px;
  width: 23px;
  height: 23px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%) !important;
}

.flagIconActive {
  font-size: 20px;
}

.flagIconInactive {
  font-size: 20px;
  position: absolute;
}

.notflagIconActive {
  font-size: 20px;
}
