.ids-uploader .ps__rail-y {
  z-index: 3;
}

.ids-uploader .uppy-Container {
  height: 100%;
}

.ids-uploader .uppy-Root {
  height: 100%;
}

.ids-uploader .uppy-Dashboard {
  height: 100%;
}