.container {
  background-color: white;
  padding: 16px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 8;
  text-align: center;
}

.root {
  overflow: hidden;
  min-height: 100%;
}

.title {
  font-size: 32;
  text-transform: uppercase;
  display: block;
}

.image {
  max-width: 500px;
  min-width: 240px;
}

.buttonContainer {
  margin: 1rem auto;
  width: 440px;
}

.button {
  border: 1px solid #ccc;
}

.button2 {
  border: 1px solid #ccc;
  padding-top: 8px;
  margin-right: 8px;
}
