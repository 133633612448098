.root {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}
.wrapperExpanded {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}
.contentContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}
.content {
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
